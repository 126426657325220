$primary-color: #008AC8;
$secondary-color: #6A7581;
$input-bg-color: #EBF2F7;
$input-text-color: #0F212E;
$input-label-color: #6A7581;
$modal-bg: #fff;

//Customize Carbon Theme
$interactive-01: $primary-color;
$interactive-02:$secondary-color;
$field-01: $input-bg-color;
$text-01: $input-text-color;
$text-02: $input-label-color;
$ui-04: $primary-color;
$focus: $primary-color;
$body-short-01: (
  font-weight: 500
);
$ui-01: $modal-bg;

@import "../../node_modules/carbon-components/scss/globals/scss/styles";
